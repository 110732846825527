import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import Container from '@mui/material/Container';
import { Helmet } from 'react-helmet-async';
import { Typography, Box, Button, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper, MenuItem, Select, FormControl, InputLabel, TextField, Pagination, Tooltip } from '@mui/material';
import InfoIcon from '@mui/icons-material/Info';
import HeartBrokenIcon from '@mui/icons-material/HeartBroken';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import VerifiedIcon from '@mui/icons-material/Verified';
import GavelIcon from '@mui/icons-material/Gavel';
import WarningIcon from '@mui/icons-material/Warning';
import TopBar from './TopBar';
import Footer from './Footer';
import { fetchVereineWithCache } from './util';

const VereinePage = () => {
  const [vereine, setVereine] = useState([]);
  const [filteredVereine, setFilteredVereine] = useState([]);
  const [selectedState, setSelectedState] = useState('');
  const [searchTerm, setSearchTerm] = useState('');
  const [page, setPage] = useState(1);
  const rowsPerPage = 10;

  const navigate = useNavigate();

  useEffect(() => {
    const loadVereine = async () => {
      const data = await fetchVereineWithCache();
      setVereine(data);
      setFilteredVereine(data);
    };

    loadVereine();
  }, []);

  const handleStateChange = (event) => {
    const selectedState = event.target.value;
    setSelectedState(selectedState);
    filterVereine(selectedState, searchTerm);
  };

  const handleSearchChange = (event) => {
    const term = event.target.value;
    setSearchTerm(term);
    filterVereine(selectedState, term);
  };

  const filterVereine = (state, term) => {
    let filtered = vereine;
    
    if (state !== '') {
      filtered = filtered.filter(verein => verein.state === state);
    }
    
    if (term !== '') {
      filtered = filtered.filter(verein => 
        verein.name.toLowerCase().includes(term.toLowerCase())
      );
    }
    
    setFilteredVereine(filtered);
    setPage(1); // Reset to first page when filtering
  };

  const handleBreakup = (verein) => {    
    navigate('/#formular', { state: { verein: verein.name, vereinAddressValid: verein.addressValid} });
  };

  const navigateVerein = (verein) => {
    const encodedName = encodeURIComponent(verein.urlName || verein.name.toLowerCase()
      .replace(/[^a-z0-9-\s]/g, '') // Remove special chars except hyphens
      .replace(/\s+/g, '-')         // Replace spaces with single hyphens
      .replace(/-+/g, '-')          // Replace multiple hyphens with single hyphen
      .trim());                     // Remove leading/trailing spaces
    navigate(`/verein/${encodedName}`, { state: { verein } });
  };
  
  const handlePageChange = (event, newPage) => {
    setPage(newPage);
  };
  
  return (
    <>
      <Helmet>
        <title>Anbauverein & CSC online kündigen | Kündigungsvorlage 2024</title>
        <meta 
          name="description" 
          content="✓ Anbauverein oder CSC schnell & einfach online kündigen ✓ Kostenlose Kündigungsvorlage ✓ Sofort Download ✓ Rechtssicher ➤ Jetzt Cannabis Social Club kündigen!" 
        />
        <meta 
          name="keywords" 
          content="Anbauverein kündigen, CSC kündigen, Cannabis Social Club Kündigung, Anbauverein Mitgliedschaft beenden, CSC Kündigungsvorlage, Anbauverein Kündigungsformular" 
        />
        <meta property="og:title" content="Anbauverein & CSC Kündigung - Kostenlose Kündigungsvorlage" />
        <meta property="og:description" content="Anbauverein oder CSC einfach online kündigen. Kostenlose Kündigungsvorlage zum Download. 100% rechtssicher." />
        <meta property="og:type" content="website" />
        <link rel="canonical" href="https://csckuendigen.de/vereine" />
      </Helmet>
      <TopBar />
      <Container maxWidth="md">
        <Box textAlign="center" mb={6}>
            <img 
              src='/logo/csc-kuendigen-phone-logo.jpg' 
              alt="CSC Kündigen Vereine Logo" 
              style={{ borderRadius: '8px', height: '200px', marginTop: 30 }} 
            />
        </Box>
        <Box display="flex" justifyContent="center" alignItems="center" flexDirection="column" mt={4}>
          <Typography variant="h1" sx={{ fontSize: '2.5rem', textAlign: 'center', mb: 2 }}>
            Anbauverein & CSC online kündigen
          </Typography>
          <Typography variant="h2" sx={{ fontSize: '1.5rem', textAlign: 'center', color: 'text.secondary', mb: 3 }}>
            Kostenlose Kündigungsvorlage für deinen Cannabis Social Club
          </Typography>
        </Box>
        <Box mt={4} display="flex" justifyContent="center" gap={2}>
          <FormControl variant="outlined" style={{ minWidth: 200 }}>
            <InputLabel>Bundesland</InputLabel>
            <Select value={selectedState} onChange={handleStateChange} label="Bundesland">
              <MenuItem value="">
                <em>Alle</em>
              </MenuItem>
              {Array.from(new Set(vereine.map(verein => verein.state))).map((state, index) => (
                <MenuItem key={index} value={state}>{state}</MenuItem>
              ))}
            </Select>
          </FormControl>
          
          <TextField
            label="Verein suchen"
            variant="outlined"
            value={searchTerm}
            onChange={handleSearchChange}
            style={{ minWidth: 200 }}
          />
        </Box>
        <Box mt={4} mb={4}>
          <Typography variant="body1" paragraph>
            Möchtest du deine Mitgliedschaft in einem Anbauverein oder Cannabis Social Club (CSC) kündigen? 
            Hier findest du alle deutschen Anbauvereine und CSCs mit der Möglichkeit, deine Kündigung direkt online zu erstellen und abzusenden.
          </Typography>
        </Box>
        <Box mt={4}>
          <TableContainer component={Paper}>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell>Name</TableCell>
                  <TableCell>Bundesland</TableCell>
                  <TableCell>Stadt</TableCell>
                  <TableCell>Status</TableCell>
                  <TableCell>Details</TableCell>
                  <TableCell>Kündigen</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {filteredVereine.length === 0 ? (
                  <TableRow>
                    <TableCell colSpan={6} align="center">
                      {vereine.length === 0 ? 
                        "Einen Moment bitte..." : 
                        "Keine Vereine gefunden"
                      }
                    </TableCell>
                  </TableRow>
                ) : (
                  filteredVereine
                    .slice((page - 1) * rowsPerPage, page * rowsPerPage)
                    .map((verein, index) => (
                      <TableRow key={index}>
                        <TableCell>
                          <Box 
                            display="flex" 
                            alignItems="center" 
                            gap={1} 
                            onClick={() => navigateVerein(verein)} 
                            style={{ cursor: 'pointer' }}
                          >
                            {verein.name}
                          </Box>
                        </TableCell>
                        <TableCell>{verein.state}</TableCell>
                        <TableCell>{verein.city}</TableCell>
                        <TableCell>
                          <Box display="flex" alignItems="center" gap={1}>
                            {verein.hasLicence ? (
                              <Tooltip title="Lizenziert">
                                <Box display="flex" alignItems="center">
                                  <CheckCircleIcon color="success" />
                                </Box>
                              </Tooltip>
                            ) : null}
                            {verein.hasSeal ? (
                              <Tooltip title="Qualitätssiegel">
                                <Box display="flex" alignItems="center">
                                  <VerifiedIcon color="primary" />
                                </Box>
                              </Tooltip>
                            ) : null}
                            {verein.isEv ? (
                              <Tooltip title="Eingetragener Verein">
                                <Box display="flex" alignItems="center">
                                  <GavelIcon color="primary" />
                                </Box>
                              </Tooltip>
                            ) : null}
                            {!verein.addressValid && (
                              <Tooltip title="Postanschrift fehlt, Kündigung nur via E-Mail möglich">
                                <WarningIcon color="warning" />
                              </Tooltip>
                            )}
                            {!verein.hasLicence && !verein.hasSeal && !verein.isEv && (
                              <Tooltip title="Keine Informationen über Lizenzierung, Qualitätssiegel oder Vereinsstatus verfügbar">
                                <Box display="flex" alignItems="center">
                                  <InfoIcon color="disabled" />
                                </Box>
                              </Tooltip>
                            )}
                          </Box>
                        </TableCell>
                        <TableCell onClick={() => navigateVerein(verein)} style={{ cursor: 'pointer' }}>
                          <InfoIcon color="primary" style={{ fontSize: 30 }} />
                        </TableCell>
                        <TableCell onClick={() => handleBreakup(verein)} style={{ cursor: 'pointer' }}>
                          <HeartBrokenIcon color="primary" style={{ fontSize: 30 }} />
                        </TableCell>
                      </TableRow>
                    ))
                )}
              </TableBody>
            </Table>
          </TableContainer>
          <Box mt={2} display="flex" justifyContent="center">
            <Pagination
              count={Math.ceil(filteredVereine.length / rowsPerPage)}
              page={page}
              onChange={handlePageChange}
              color="primary"
            />
          </Box>
        </Box>
        <Box mt={4} mb={4}>
          <Typography variant="h3" sx={{ fontSize: '1.3rem', mb: 2 }}>
            So kündigst du deinen Anbauverein oder CSC:
          </Typography>
          <ol>
            <li>Wähle deinen Anbauverein oder CSC aus der Liste</li>
            <li>Klicke auf das Kündigungssymbol</li>
            <li>Fülle das Kündigungsformular aus</li>
            <li>Wähle zwischen E-Mail oder Brief+E-Mail Versand</li>
            <li>Bezahle sicher per Kreditkarte</li>
          </ol>
          <Typography variant="body1" sx={{ mt: 2, fontWeight: 500 }}>
            Deine Kündigung wird sofort nach Bezahlung professionell erstellt und versendet. Du erhältst alle Unterlagen per E-Mail.
          </Typography>
        </Box>
        <Box mt={4} mb={4}>
          <Button variant="contained" color="primary" fullWidth href="mailto:kontakt@csckuendigen.de">
            Es fehlt ein Verein? Schreibe uns eine Email!
          </Button>
        </Box>
        <Footer />
      </Container>
    </>
  );
};

export default VereinePage;
