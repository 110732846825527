import React, { useEffect, useState } from 'react';
import { useLocation, useParams, useNavigate } from 'react-router-dom';
import Container from '@mui/material/Container';
import { Helmet } from 'react-helmet-async';
import { Typography, Box, Paper, Button, Link, CircularProgress, Grid } from '@mui/material';
import HeartBrokenIcon from '@mui/icons-material/HeartBroken';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import VerifiedIcon from '@mui/icons-material/Verified';
import GavelIcon from '@mui/icons-material/Gavel';
import WarningIcon from '@mui/icons-material/Warning';
import InfoIcon from '@mui/icons-material/Info';
import TopBar from './TopBar';
import Footer from './Footer';
import { cfg, fetchVereineWithCache } from './util';

const VereinPage = () => {
  const location = useLocation();
  const { urlName } = useParams();
  const navigate = useNavigate();
  const [verein, setVerein] = useState(location.state?.verein || null);

  useEffect(() => {
    const fetchVerein = async () => {
      try {
        const allVereine = await fetchVereineWithCache();
        const cachedVerein = allVereine.find(v => {
          if (v.urlName === urlName) return true;
          
          const encodedVName = v.name.toLowerCase()
            .replace(/[^a-z0-9-\s]/g, '')
            .replace(/\s+/g, '-')
            .replace(/-+/g, '-')
            .trim();
          
          return encodeURIComponent(encodedVName) === urlName;
        });
        
        if (cachedVerein) {
          setVerein(cachedVerein);
          return;
        }

        const response = await fetch(`${cfg('BACKEND_API_URL')}/verein/${urlName}`);
        if (!response.ok) {
          throw new Error(`HTTP error! status: ${response.status}`);
        }
        const data = await response.json();
        setVerein(data);
      } catch (error) {
        console.error('Error fetching the verein:', error);
      }
    };

    if (!verein) {
      fetchVerein();
    }
  }, [urlName, verein]);

  const handleBreakup = () => {
    navigate('/#formular', { state: { verein: verein.name, vereinAddressValid: verein.addressValid } });
  };

  const handleBack = () => {
    navigate('/vereine');
  };

  if (!verein) {
    return (
      <Container maxWidth="md" style={{ textAlign: 'center', marginTop: '2rem' }}>
        <CircularProgress />
        <Typography variant="h4" mt={2}>
          Laden...
        </Typography>
      </Container>
    );
  }

  return (
    <>
      <Helmet>
        <title>{`${verein.name} kündigen - Einfach online kündigen - Anbauverein Kündigungsvorlage`}</title>
        <meta name="description" content={`${verein.name} schnell & einfach kündigen ✓ Kostenlose Kündigungsvorlage ✓ Sofort Download ✓ Kündigungsfrist beachten ✓ Jetzt ${verein.name} Mitgliedschaft beenden`} />
        <meta name="keywords" content={`${verein.name} kündigen, ${verein.name} Mitgliedschaft kündigen, ${verein.name} Kündigung, Kündigungsvorlage ${verein.name}, ${verein.name} Vertrag beenden`} />
        <link rel="canonical" href={`https://csckuendigen.de/verein/${urlName}`} />
        <meta property="og:title" content={`${verein.name} kündigen - Anbauverein Kündigungsvorlage & Online Service`} />
        <meta property="og:description" content={`${verein.name} bequem online kündigen. Kündigungsvorlage kostenlos erstellen und direkt versenden. Jetzt ${verein.name} Mitgliedschaft beenden!`} />
      </Helmet>

      <TopBar />
      <Container maxWidth="md">
        <Box display="flex" justifyContent="center" alignItems="center" flexDirection="column">
          <Paper elevation={3} style={{ padding: '16px', width: '100%', marginTop: 30 }} >
            <Typography variant="h1" gutterBottom>
              {verein.name} kündigen
            </Typography>
            <Typography variant="h2" gutterBottom sx={{ fontSize: '1.5rem', mb: 4 }}>
              Schnell & einfach {verein.name} Mitgliedschaft online kündigen
            </Typography>
            <Typography variant="body1" gutterBottom sx={{ mb: 4 }}>
              Du möchtest deine Mitgliedschaft bei {verein.name} kündigen? 
              Hier findest du alle wichtigen Informationen zur Kündigung bei {verein.name} 
              sowie unseren kostenlosen Service zur direkten Online-Kündigung.
            </Typography>
            <Typography variant="h6" gutterBottom>
              Name: {verein.name}
            </Typography>
            {(verein.address || verein.zip || verein.city) && (
              <Typography variant="h6" gutterBottom>
                Adresse: {[verein.address, verein.zip, verein.city].filter(Boolean).join(', ')}
              </Typography>
            )}
            {verein.state && (
              <Typography variant="h6" gutterBottom>Bundesland: {verein.state}</Typography>
            )}
            {verein.web && (
              <Typography variant="h6" gutterBottom>
                Website: <Link href={verein.web} target="_blank" rel="noopener noreferrer">{verein.web}</Link>
              </Typography>
            )}
            <Grid container spacing={2} sx={{ mt: 2, mb: 2 }}>
              <Grid item xs={12} sm={6} md={3}>
                <Box display="flex" flexDirection="column" alignItems="center" textAlign="center">
                  {verein.addressValid ? (
                    <>
                      <InfoIcon color="success" sx={{ fontSize: 40, mb: 1 }} />
                      <Typography>Postanschrift vorhanden</Typography>
                    </>
                  ) : (
                    <>
                      <WarningIcon color="warning" sx={{ fontSize: 40, mb: 1 }} />
                      <Typography>Nur E-Mail-Kündigung möglich, Adresse unbekannt</Typography>
                    </>
                  )}
                </Box>
              </Grid>

              <Grid item xs={12} sm={6} md={3}>
                <Box display="flex" flexDirection="column" alignItems="center" textAlign="center">
                  <CheckCircleIcon color={verein.hasLicence ? "success" : "error"} sx={{ fontSize: 40, mb: 1 }} />
                  <Typography>
                    {verein.hasLicence ? "Lizenziert" : "Keine Lizenz"}
                  </Typography>
                </Box>
              </Grid>

              <Grid item xs={12} sm={6} md={3}>
                <Box display="flex" flexDirection="column" alignItems="center" textAlign="center">
                  <VerifiedIcon color={verein.hasSeal ? "primary" : "error"} sx={{ fontSize: 40, mb: 1 }} />
                  <Typography>
                    {verein.hasSeal ? "Qualitätssiegel" : "Kein Qualitätssiegel"}
                  </Typography>
                </Box>
              </Grid>

              <Grid item xs={12} sm={6} md={3}>
                <Box display="flex" flexDirection="column" alignItems="center" textAlign="center">
                  <GavelIcon color={verein.isEv ? "primary" : "error"} sx={{ fontSize: 40, mb: 1 }} />
                  <Typography>
                    {verein.isEv ? "Eingetragener Verein" : "Kein eingetragener Verein"}
                  </Typography>
                </Box>
              </Grid>
            </Grid>

            <Box display="flex" justifyContent="space-between" mt={4}>
              <Button 
                variant="outlined" 
                startIcon={<ArrowBackIcon />} 
                onClick={handleBack}
              >
                Zurück zu Vereine
              </Button>
              <Button 
                variant="contained" 
                color="secondary" 
                startIcon={<HeartBrokenIcon />} 
                onClick={handleBreakup}
              >
                Kündigen
              </Button>
            </Box>
          </Paper>
        </Box>
        <Footer />
      </Container>
    </>
  );
};

export default VereinPage;
